/**
 * Created by Bart Decorte
 * Date: 07/03/2018
 * Time: 16:05
 */

require([
  'requirejs/require'
], function() {

});
define("../resources/assets/js/client/head", function(){});

